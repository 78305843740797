import React from 'react'
import Landing from '../../src/components/pages/Landing.jsx'

function Success() {
  return (
<>
<Landing/>
</>
  )
}

export default Success